import React from "react";
import PropTypes from "prop-types";
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";

// Get location
import styles from "./CompanyLogos.module.scss";

class CompanyLogos extends React.Component {
	static propTypes = {
		isWhite: PropTypes.bool
	};

	static defaultProps = {
		isWhite: false
	};

	constructor(props) {
		super(props);
		this.state = {
			toggleMenu: false
		};
	}

	toggleMenu = () => {
		this.setState(prevState => ({
			toggleMenu: !prevState.toggleMenu
		}));
	};

	render() {
		const isMenuActive = this.state.toggleMenu ? "is-active" : "";
		const isWhite = this.props.isWhite ? "" : "primaryBar";
		const primaryCTAColour = this.props.isWhite
			? "buttonSecondary"
			: "is-link is-inverted";
		// Set logo colour
		const logoAira = this.props.data.logoAira.childImageSharp.fluid;
		const logoHeadbox = this.props.data.logoHeadbox.childImageSharp.fluid;
		const logoDeGruyter = this.props.data.logoDeGruyter.childImageSharp
			.fluid;
		const logoLoveCrafts = this.props.data.logoLoveCrafts.childImageSharp
			.fluid;

		return (
			<div className="greyBar p-t-md imageContainer">
				<div className={`container maxContainer outerContainer`}>
					<div className="columns">
						<div className="column">
							<Img
								className={styles.overrideFluidSize}
								imgStyle={{ objectFit: "contain" }}
								fluid={logoAira}
								alt=""
								fadeIn={false}
							/>
						</div>
						<div className="column">
							<Img
								className={styles.overrideFluidSize}
								imgStyle={{ objectFit: "contain" }}
								fluid={logoHeadbox}
								alt=""
								fadeIn={false}
							/>
						</div>
						<div className="column">
							<Img
								className={styles.overrideFluidSize}
								imgStyle={{ objectFit: "contain" }}
								fluid={logoDeGruyter}
								alt=""
								fadeIn={false}
							/>
						</div>
						<div className="column">
							<Img
								className={styles.overrideFluidSize}
								imgStyle={{ objectFit: "contain" }}
								fluid={logoLoveCrafts}
								alt=""
								fadeIn={false}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
// export default CompanyLogos;

export default () => (
	<StaticQuery
		query={graphql`
			query {
				logoAira: file(
					relativePath: { eq: "images/logos/aira-logo-fixed-1.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1200, maxHeight: 646) {
							...GatsbyImageSharpFluid
						}
					}
				}

				logoHeadbox: file(
					relativePath: { eq: "images/logos/headbox-fixed1.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1200, maxHeight: 646) {
							...GatsbyImageSharpFluid
						}
					}
				}

				logoDeGruyter: file(
					relativePath: { eq: "images/logos/de-gruyter.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1200, maxHeight: 646) {
							...GatsbyImageSharpFluid
						}
					}
				}

				logoLoveCrafts: file(
					relativePath: { eq: "images/logos/lc-logo-fixed1.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1200, maxHeight: 646) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={data => <CompanyLogos data={data} />}
	/>
);
