import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTwoColumnIconSmall from "src/components/generic/StripTwoColumnIconSmall/StripTwoColumnIconSmall";

import HowItWorks from "src/components/generic/HowItWorks/HowItWorks";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import CompanyLogos from "src/components/generic/CompanyLogos/CompanyLogos";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import socialShare from "static/images/social_sharing/homepage.png";

import { REPORT_DATA } from "src/routesConstants";

// Styles
import {
	FaLayerGroup,
	FaProjectDiagram,
	FaChartLine,
	FaClock
} from "react-icons/fa";
import styles from "./index.module.scss";

// Icons

const title = (
	<React.Fragment>
		<span className="block p-b-m">
			All your SEO data with best in class reporting.
		</span>
	</React.Fragment>
);

// eslint-disable-next-line no-lone-blocks
{
	/* <span>Get better SEO insights:</span>
<span className={`fakeList`}>In half the time.</span>
<span className={`fakeList`}>
	Because you have <i>all</i> your data.
</span> */
}

const subTitle = (
	<p>
		Piped Out is a managed data service for your SEO team. It&apos;s like
		hiring &#x9;&#x9;a data engineer and data analyst, both with deep domain
		expertise & their own tool stack. We&apos;ll get rid of your data
		problems and make you better at your job.
	</p>
);

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	const whatIsIt = [
		{
			subTitle: "Getting the most out of your data.",
			content: (
				<React.Fragment>
					<p>
						We&apos;ll take all your different SEO data sources and
						get them in one place (a data warehouse), where everyone
						can easily and powerfully work with them.
					</p>
					<p>
						We&apos;ll roll out best in class reporting and give you
						tools for ad-hoc analysis as well.
					</p>
				</React.Fragment>
			),
			icon: null,
			imageLarge: true,
			image: data.pipedOutDiagramNew.childImageSharp.fluid,
			topPad: true
		}
	];

	const contentListFour = [
		{
			title: "All of your data in one place.",
			content: (
				<React.Fragment>
					<p>
						You&apos;ve got UA & GA4 data which you want to join
						together.
					</p>
					<p>
						Your blog is Wordpress, but your site is Magento.
						You&#39;ve got two different sources of logs you need to
						pull together to run a proper analysis.
					</p>
					<p>
						You&#39;ve got your pre-migration domain and your
						post-migration domain. You want Search Console data from
						both to monitor your migration.
					</p>
					<p>We&apos;ll get all your data in one place.</p>
				</React.Fragment>
			),
			icon: <FaLayerGroup />
		},
		{
			title: "Powerful reporting templates",
			content: (
				<React.Fragment>
					<p>
						We&apos;ve got years of experience in SEO and have a
						library of{" "}
						<a href={REPORT_DATA} target="_blank" rel="noreferrer">
							{" "}
							high quality reports{" "}
						</a>
						for common SEO tasks.
					</p>
					<p>
						Not sure how to display your ranking data? We&apos;ve
						got a template for you.
					</p>
					<p>
						Need help debugging traffic drops? We&apos;ve got a
						template for that.
					</p>
					<p>
						We can also work with you to build custom reports if you
						need something specialised.
					</p>
				</React.Fragment>
			),
			icon: <FaChartLine />
		},
		{
			title: "No more artificial limits.",
			content: (
				<React.Fragment>
					<p>
						The Google Search Console interface only gives you 1,000
						rows of data. And it only stores 16 months. We&apos;ll
						set-up the export, store your data and backfill so
						you&apos;ve got all of it and can filter & segment
						however you want.
					</p>
					<p>
						Google Analytics samples if you use any kind of segments
						or filters. We&apos;ll store your data and help you
						avoid sampling.
					</p>
					<p>
						Log files won&#39;t even fit in a spreadsheet. We can
						help you handle any scale.
					</p>
					<p>
						We store all your data sources and even if you leave us,
						you can take the data with you.
					</p>
				</React.Fragment>
			),
			icon: <FaProjectDiagram />
		},

		{
			title: "Repeat analysis instantly.",
			content: (
				<React.Fragment>
					<p>
						That cannibalisation audit you did for the UK market? We
						loved it and want it done on US.
					</p>
					<p>
						That report you built for widget A team? It&#39;s great
						and we should make one for the widget B team.
					</p>
					<p>
						Changing datasets and segments for a piece of analysis
						is as simple as changing a dropdown field in our query
						builder.
					</p>
					<p>We want repeating work to be fast and easy.</p>
				</React.Fragment>
			),
			icon: <FaClock />
		}
	];
	// const alternatingContent = [
	// 	{
	// 		subTitle: "All of your data in one place.",
	// 		content: (
	// 			<React.Fragment>
	// 				<p>
	// 					You&apos;ve got UA & GA4 data which need to join
	// 					together.
	// 				</p>
	// 				<p>
	// 					Your blog is Wordpress, but your site is Magento.
	// 					You&#39;ve got two different sources of logs you need to
	// 					pull together to run a proper analysis.
	// 				</p>
	// 				<p>
	// 					You&#39;ve got your pre-migration domain and your
	// 					post-migration domain. You want search console data from
	// 					both to monitor your migration.
	// 				</p>
	// 				<p>We&apos;ll get all your data in one place.</p>
	// 			</React.Fragment>
	// 		),
	// 		icon: <FaLayerGroup />,
	// 		image: data.alternatingImg1.childImageSharp.fluid
	// 	},
	// 	{
	// 		subTitle: "Best in class reproting",
	// 		content: (
	// 			<React.Fragment>
	// 				<p>
	// 					The Google Search Console interface only gives you 1,000
	// 					rows of data. And it only stores 16 months. We&apos;ll
	// 					store all of it for as long as you want and let you
	// 					filter & segment however you want.
	// 				</p>
	// 				<p>
	// 					Google Analytics samples if you use any kind of segments
	// 					or filters. We&apos;ll store your data and help you
	// 					avoid sampling.
	// 				</p>
	// 				<p>
	// 					Log files won&#39;t even fit in a spreadsheet. We can
	// 					help you handle any scale.
	// 				</p>
	// 				<p>
	// 					We store all your data sources and even if you leave us,
	// 					you can take the data with you.
	// 				</p>
	// 			</React.Fragment>
	// 		),
	// 		image: data.alternatingImg2.childImageSharp.fluid
	// 	},
	// 	{
	// 		subTitle: "Quick powerful analysis",
	// 		content: (
	// 			<React.Fragment>
	// 				<p>
	// 					Let&apos;s give a quick example: You want to find pages
	// 					which are cannibalising.
	// 				</p>
	// 				<ul>
	// 					<li>You open our query engine</li>
	// 					<li>Select the cannibalisation query</li>
	// 					<li>
	// 						Select the Google Search Console properties & the
	// 						date range you want
	// 					</li>
	// 					<li>Hit the copy button and paste it into BigQuery</li>
	// 				</ul>
	// 				<p>All the data heavy lifting done in under a minute!</p>
	// 				<p>
	// 					We&apos;ve got prewritten queries for many common SEO
	// 					tasks, and if we&apos;re missing important ones you
	// 					need, we&apos;ll work with you to create them.
	// 				</p>
	// 			</React.Fragment>
	// 		),
	// 		video: queryVid
	// 	}
	// ];

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageImage={socialShare} />
			<StripPrimary
				title={title}
				subTitle={subTitle}
				image={data.pipedOutDiagram.childImageSharp.fluid}
				fullSized={true}
				isWhite={true}
				reportingButton={true}
				// includeIntroVideo={true}
			/>
			<CompanyLogos />
			<section>
				<StripsAlternatingContent stripList={whatIsIt} topPad={true} />
			</section>
			<HowItWorks />
			<section>
				<StripTwoColumnIconSmall contentList={contentListFour} />
			</section>
			{/* <section>
				<StripTitle title="How can we help?" />
				<StripsAlternatingContent stripList={alternatingContent} />
			</section> */}
		</Layout>
	);
};

// Add back in when I have content
// eslint-disable-next-line no-lone-blocks
{
	/* <div className="greyBar">
				<section className={`container ${styles.readMore}`}>
					<div className="columns p-t-lg p-b-lg is-desktop">
						<div className="column is-one-third-desktop">
							<span className="primaryBox"></span>
							<h2 className={`m-b-m`}>Learn</h2>
							<PostListingSlim postEdges={data.text.edges} />
						</div>
						<div className="column is-one-third-desktop">
							<span className="primaryBox"></span>
							<h2 className={`m-b-m`}>Watch</h2>
							<PostListingSlim postEdges={data.watch.edges} />
						</div>
						<div className="column is-one-third-desktop">
							<span className="primaryBox"></span>
							<h2 className={`m-b-m `}>Tools</h2>
							<PostListingSlim postEdges={data.tools.edges} />
						</div>
					</div>
				</section>
			</div> */
}

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query IndexQuery {
		# This gets us all of the nodes.
		# This gets the main image
		primaryImage: file(
			relativePath: {
				eq: "images/pages/homepage/homepage_stripprimary.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 935, maxHeight: 575) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# These get us all the alternating images
		alternatingImg1: file(
			relativePath: { eq: "images/pages/homepage/bigquery_tables2.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 546, maxHeight: 326) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: { eq: "images/pages/homepage/bigquery_size.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 513, maxHeight: 278) {
					...GatsbyImageSharpFluid
				}
			}
		}
		pipedOutDiagram: file(
			relativePath: { eq: "images/pages/homepage/homepage_intro.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 900, maxHeight: 726) {
					...GatsbyImageSharpFluid
				}
			}
		}
		pipedOutDiagramNew: file(
			relativePath: {
				eq: "images/pages/homepage/piped_out_diagram_new_2.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 879, maxHeight: 986) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
